<template lang="pug">
include ../../../../configs/template
div.row
  div.text-left.col-12
    h3.text-left
      b {{$t('infoShip')}}:
  div.col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.number_vessel','numShip','["required", "maxLength"]')
  div.col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.name_vessel','nameShip','["required", "maxLength"]')
  div.col-sm-12.col-md-6.col-xl-4
    +select-validation('body.additional_info.type_vessel', 'liftTypeShip', 'typeShip', 'nameLang', '["required"]')
  div.col-sm-12.col-md-6
    +select-validation('body.additional_info.mode_of_navigation','listModeShipping', 'modeShipping', 'nameLang', '["required"]')
  div.col-sm-12.col-md-6
    +field-validation('body.additional_info.port_of_registration','portShip','["required"]')
  div.col-12
    +field-validation('body.additional_info.ship_owner','ownerShip','["required", "maxLength"]')
  div.col-sm-12.col-md-6.col-xl-3
    +field-validation('body.additional_info.gross_capacity','grossCapacity','["required"]')
  div.col-sm-12.col-md-6.col-xl-3
    +field-validation('body.additional_info.propulsion_power','powerGEU','["required", "numeric"]')
  div.col-sm-12.col-md-6.col-xl-3
    +field-validation('body.additional_info.refrigerating_power','coldProductivity','["numeric"]')
  div.col-sm-12.col-md-6.col-xl-3
    +field-validation('body.additional_info.electrical_power','elEquipmentPower','["numeric"]')
  div.col-12.d-flex.align-end.pa-0
    div.col-sm-12.col-md-6.col-xl-5
      +select-validation('body.additional_info.type_geu','listTypeGEU', 'typeGEU', 'nameLang', '["required"]')
    div.col-sm-12.col-md-6.col-xl-5
      +field-validation('body.additional_info.level_refriger_plant','levelRefrigerantPlant','[]')
    div.text-left.col-sm-12.col-xl-2
      +switch('body.additional_info.equipment_gmzlb','present','missingFemale', 'apparatusGMZLB')
  div.col-sm-12.col-md-6.d-flex.align-end
    +field-validation('body.additional_info.trading_area','swimArea','["required"]')
  div.col-sm-12.col-md-6.d-flex.align-end
    +field-validation('body.additional_info.ports_input','swimPorts','["required"]')
  div.text-left.col-12
    h3.text-left
      b {{$t('captainEng')}}:
  div.col-sm-12
    +field-validation('body.additional_info.full_name_master_eng','captainEng','["required", "alphaEN"]')
  div.text-left.col-12
    h3.text-left
      b {{$t('captain')}}:
  div.col-sm-12
    +field-validation('body.additional_info.full_name_master_ukr','captain','["required", "alphaUA"]')
  div.text-left.col-12
    h3.text-left
      b {{$t('experience')}}:
  div.text-left.col-12
    +switch('body.additional_info.book_registration_practical','present','missingFemale', 'bookPractical')
  div.col-sm-12.col-md-9
    +field-validation('body.additional_info.place_start','hirePlace','["required", "maxLength"]')
  div.col-sm-12.col-md-3
    +date-picker-validation('body.additional_info.date_start','hireDate', 'dateObjectStart', '["required", "minValue", "maxValue"]')(
      min="1900-01-01" :max="body.additional_info.date_end"
    )
  div.col-sm-12.col-md-9
    +field-validation('body.additional_info.place_end','firePlace','["required", "maxLength"]')
  div.col-sm-12.col-md-3
    +date-picker-validation('body.additional_info.date_end','fireDate', 'dateObjectEnd', '["required", "minValue", "maxValue"]')(
      :min="body.additional_info.date_start" max="2200-01-01"
    )
  div.text-left.col-12
    +switch('body.additional_info.is_repaired','yes','no', 'repairedShip')
  div(v-if="body.additional_info.is_repaired").col-12.row
    div.col-sm-12.col-md-4
      +date-picker-validation('body.additional_info.repair_date_from', 'periodStart', 'dateObjectRepairFrom', '["required", "minValue", "maxValue"]', 'readOnlyDate')
    div.col-sm-12.col-md-4
      +date-picker-validation('body.additional_info.repair_date_to', 'periodEnd', 'dateObjectRepairTo', '["required", "minValue", "maxValue"]', 'readOnlyDate')
    div.col-sm-12.col-md-1.d-flex.justify-content-center.align-items-center
      label {{ $t('or') }}
    div.col-sm-12.col-md-3
      +field-validation('body.additional_info.days_repair', 'totalDays', '["required"]')(
        :readonly="readonlyOnlyWorkDay")
  div.col-12
    +select-validation('body.additional_info.position','listPositionsShip', 'positionOnShip', 'nameLang','["required"]')
  div.text-left.col-12
    h3.text-left
      b {{$t('responsibility')}}:
  div.d-flex.col-12
    +select('chooseResponsibility','listResponsibility', 'responsibility', 'nameLang')(return-object)
    v-tooltip(left)
      template(#activator="{ on, attrs }")
        v-btn(icon color="success" @click="addResponsibility" v-bind="attrs" v-on="on").ml-4
          v-icon mdi-plus-outline
      span {{$t('documentIconControl.add responsibility')}}
  div(
    v-for="(resp, index) in body.additional_info.all_responsibility"
    :key="resp.responsibility").col-12.row
    div.d-flex.justify-space-between.col-12
      label {{resp.ua}}
      v-tooltip(left)
        template(#activator="{ on, attrs }")
          v-btn(icon color="error"  v-bind="attrs" v-on="on" @click="deleteResponsibility(resp.responsibility)")
            v-icon mdi-close-outline
        span {{$t('documentIconControl.remove responsibility')}}
    div.col-sm-12.col-md-4
      +date-picker-validation('body.additional_info.all_responsibility[index].date_from', 'periodStart', 'body.additional_info.all_responsibility.$each[index].date_from', '["required"]', 'readOnlyDate')(
        :min="body.additional_info.date_start"
        :max="body.additional_info.all_responsibility[index].date_to"
      )
    div.col-sm-12.col-md-4
      +date-picker-validation('body.additional_info.all_responsibility[index].date_to', 'periodEnd', 'body.additional_info.all_responsibility.$each[index].date_to', '["required"]', 'readOnlyDate')(
        :min="body.additional_info.all_responsibility[index].date_from"
        :max="body.additional_info.date_end"
      )
    div.col-sm-12.col-md-1.d-flex.justify-content-center.align-items-center
      label {{ $t('or') }}
    div.col-sm-12.col-md-3
      +field-validation('body.additional_info.all_responsibility[index].days_work', 'totalDays', '["required"]')(
        @blur="$v.body.additional_info.all_responsibility.$each[index].days_work.$touch()"
        :error-messages="$valid.validation($v.body.additional_info.all_responsibility.$each[index].days_work, ['required'])"
        :readonly="readonlyOnlyWorkDay"
        )
</template>
<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { addNameFile } from '@/mixins/main'
import { STATUSES_DOCUMENT_CONSTANTS, TYPE_DOCUMENTS_CONSTANTS } from '@/configs/constants'
const initBody = () => (
  {
    additional_info: {
      service_record: null,
      name_vessel: null,
      type_vessel: null,
      port_of_registration: null,
      mode_of_navigation: null,
      type_geu: null,
      ship_owner: null,
      number_vessel: null,
      propulsion_power: null,
      electrical_power: null,
      all_responsibility: [],
      refrigerating_power: null,
      book_registration_practical: false,
      position: null,
      date_start: null,
      place_start: null,
      place_end: null,
      date_end: null,
      full_name_master: null,
      full_name_master_eng: null,
      full_name_master_ukr: null,
      equipment_gmzlb: false,
      trading_area: null,
      ports_input: null,
      status_document: STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.VERIFICATION,
      gross_capacity: null,
      level_refriger_plant: null,
      number_page_book: null,
      is_repaired: false,
      repair_date_from: null,
      repair_date_to: null,
      days_repair: null
    }
  }
)
export default {
  props: {
    sailorExperienceCertificate: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      addNameFile,
      isLoading: true,
      chooseResponsibility: null,
      TYPE_DOCUMENTS_CONSTANTS,
      STATUSES_DOCUMENT_CONSTANTS,
      body: initBody(),
      selected_photos: []
    }
  },
  validations () { return verificationSC.sailorRecordBookLine(this) },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      serviceRecordBook: state => state.sailor.serviceRecordBook.results,
      listModeShipping: state => state.directory.modeShipping,
      liftTypeShip: state => state.directory.typeShip,
      listTypeGEU: state => state.directory.typeGEU,
      listResponsibility: state => state.directory.responsibility,
      listPositionsShip: state => state.directory.positionsShip,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    dateObjectStart () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateObjectEnd () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    },
    dateObjectRepairFrom () {
      return this.body.additional_info.repair_date_from ? new Date(this.body.additional_info.repair_date_from) : null
    },
    dateObjectRepairTo () {
      return this.body.additional_info.repair_date_to ? new Date(this.body.additional_info.repair_date_to) : null
    },
    dateObjectFrom () {
      return this.body.additional_info.repair_date_from ? new Date(this.body.additional_info.repair_date_from) : null
    },
    dateObjectTo () {
      return this.body.additional_info.repair_date_to ? new Date(this.body.additional_info.repair_date_to) : null
    },
    readOnlyDate () {
      if (this.body.additional_info.all_responsibility.some(el => el.days_work)) return true
      else if (this.body.additional_info.days_repair) return true
      else return false
    },
    readonlyOnlyWorkDay () {
      if (this.body.additional_info.all_responsibility.some(el => el.date_to || el.date_from)) return true
      else if (this.body.additional_info.repair_date_from) return true
      else if (this.body.additional_info.repair_date_to) return true
      else return false
    }
  },
  mounted () {
    if (Object.keys(this.sailorExperienceCertificate).length) {
      Object.keys(this.body.additional_info).forEach(key => {
        if (key === 'full_name_master_ukr') this.body.additional_info[key] = this.sailorExperienceCertificate.full_name_master
        else this.body.additional_info[key] = this.sailorExperienceCertificate[key]
        if (key === 'all_responsibility') {
          this.body.additional_info[key] = this.sailorExperienceCertificate[key].map(el => {
            if (el.responsibility) el.ua = this.getDirectoryObject({ id: el.responsibility, value: 'responsibility' })[this.nameLang]
            return el
          })
        }
      })
    }
  },
  methods: {
    ...mapActions(['getExperienceReferences']),
    addResponsibility () {
      if (!this.body.additional_info.all_responsibility.some(el => el.responsibility === this.chooseResponsibility?.id) && this.chooseResponsibility) {
        this.body.additional_info.all_responsibility.unshift({
          responsibility: this.chooseResponsibility.id,
          ua: this.chooseResponsibility.name_ukr,
          en: this.chooseResponsibility.name_eng,
          days_work: null,
          date_from: null,
          date_to: null,
          is_repaired: false
        })
      } else if (!this.chooseResponsibility) {
        this.$notification.warning('notify.warning.choose_responsibility_for_add')
      } else this.$notification.warning('notify.warning.responsibility_was_has_already_added')
      this.chooseResponsibility = null
    },
    deleteResponsibility (id) {
      this.body.additional_info.all_responsibility = this.body.additional_info.all_responsibility.filter(el => el.responsibility !== id)
    },
    daysWorkReadonly (index) {
      return !!this.body.additional_info.all_responsibility[index].date_from || !!this.body.additional_info.all_responsibility[index].date_to
    },
    daysFromToReadonly (index) {
      return !!this.body.additional_info.all_responsibility[index].days_work
    },
    clearBody () {
      const { params } = this.$route
      this.getExperienceReferences({ params })
      this.body = initBody()
      this.$v.$reset()
    }
  }
}
</script>
